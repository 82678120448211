<template>
  <div class="messages">
      <b-row v-for="(val, key, index) in value" class="form-group" :key="index">
        <b-col cols="4">
          <b-form-input size="sm" type="text" :value="key" @input="onKeyChange(key, $event)"></b-form-input>
        </b-col>
        <b-col cols="6">
          <b-form-input size="sm" type="text" :value="val" @input="onValChange(key, $event)"></b-form-input>
        </b-col>
        <b-col cols="2">
         <b-button variant="danger" size="sm" @click="onRemove(key)" class="btn-pill">
              <i class="cui-circle-x icons"></i>
         </b-button>
        </b-col>
      </b-row>
      <div class="form-group form-actions">
        <b-button size="sm" variant="secondary" @click="addKeyValue">Add</b-button>
      </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      counter: 1
    };
  },
  props: ['value'],
  methods: {
    onValChange(key, newVal) {
      const clone = JSON.parse(JSON.stringify(this.value));
      clone[key] = newVal;
      this.$emit('input', clone);
    },
    onKeyChange(key, newKey) {
      const clone = JSON.parse(JSON.stringify(this.value));
      clone[newKey] = clone[key];
      delete clone[key];
      this.$emit('input', clone);
    },
    onRemove(key) {
      const clone = JSON.parse(JSON.stringify(this.value));
      delete clone[key];
      this.$emit('input', clone);
    },
    addKeyValue() {
      const clone = JSON.parse(JSON.stringify(this.value));
      clone[`name${this.counter++}`] = '';
      this.$emit('input', clone);
    }
  }
};
</script>
