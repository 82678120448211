<template>
  <div class="wrapper">
    <b-row>
      <b-col md="6">
        <b-card header="Create Gateway Image">
          <b-form>
          <fieldset>
            <legend class="h5">Default Env</legend>
            <b-form-group label-align-sm="right" label-cols-sm="3" label="Gateway ID:" label-for="gateway-id">
              <b-form-input
                id="gateway-id"
                ref="gateway-id"
                v-model.trim="defaultEnv.GATEWAY_ID"
                placeholder="DGID000000000"
                :state="gatewayIdValid"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label-align-sm="right" label-cols-sm="3"  label="Gateway Name:" label-for="gateway-name">
              <b-form-input
                id="gateway-name"
                ref="gateway-name"
                v-model.trim="defaultEnv.GATEWAY_NAME"
                placeholder="Gateway at location"
                autocomplete="off"
                aria-describedby="name-feedback"
                :state="nameValid"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="name-feedback">
                Enter at least 3 letters
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label-align-sm="right" label-cols-sm="3"  label="Site Code:" label-for="site-code">
              <b-form-input
                id="site-code"
                ref="site-code"
                v-model.trim="defaultEnv.GATEWAY_SITE_CODE"
                placeholder="D-XXX"
                autocomplete="off"
                :state="siteCodeValid"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label-align-sm="right" label-cols-sm="3"  label="Timezone:" label-for="gateway-tz">
              <b-form-input
                id="gateway-tz"
                placeholder="America/Toronto"
                :state="timezoneValid"
                v-model.trim="defaultEnv.GATEWAY_TZ"
                list="timezone-list"
                required
              ></b-form-input>
              <datalist id="timezone-list">
                <option v-for="tz in timezoneList" :key="tz">{{tz}}</option>
              </datalist>
            </b-form-group>
            <b-form-group label-size="sm" label-align-sm="right" label-cols-sm="3"  label="Reverse SSH Port:" label-for="gateway-revssh-port">
              <b-form-input
                id="gateway-revssh-port"
                ref="gateway-revssh-port"
                type="number"
                placeholder="50000"
                v-model="defaultEnv.GATEWAY_REVSSH_PORT"
                :disabled="!issueRevsshKeys"
                :required="issueRevsshKeys"
                :state="issueRevsshKeys ? portValid : null"
                aria-describedby="port-feedback"
                number
              ></b-form-input>
              <b-form-invalid-feedback id="port-feedback">
                Port should be bigger than 50000 and smaller than 50999
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label-align-sm="right" label-cols-sm="3"  label="Environment:" label-for="gateway-environment">
              <b-form-input
                id="gateway-environment"
                ref="gateway-environment"
                placeholder="production"
                :options="['production', 'development']"
                v-model="defaultEnv.GATEWAY_ENV"
                list="environment-list"
                :state="envValid"
                required
              ></b-form-input>
              <datalist id="environment-list">
                <option>production</option>
                <option>development</option>
              </datalist>
            </b-form-group>
            </fieldset>

            <fieldset>
              <legend class="h5">Custom Env</legend>
              <key-value-editor v-model="extraEnv"></key-value-editor>
            </fieldset>
            <fieldset>
              <legend class="h5">Options</legend>

            <b-form-checkbox
              id="revssh-key"
              v-model="issueRevsshKeys"
              name="revssh-key"
            >Setup reverse SSH</b-form-checkbox>
            <b-form-checkbox
              id="broker-cred"
              v-model="issueBrokerCredentials"
              name="broker-cred"
            >Issue broker credentials</b-form-checkbox>
            <b-form-checkbox
              id="google-creds"
              v-model="issueGoogleCloudCredentials"
              name="google-creds"
            >Issue Google Cloud credentials</b-form-checkbox>
            <b-form-radio-group
                class="pt-2"
                v-model="imgTarget"
                :options="imgTargetOptions"
              ></b-form-radio-group>

            </fieldset>
          </b-form>
          <template v-slot:footer>
            <b-dropdown :disabled="downloadDisabled" class="float-right" variant="primary" @click="downloadImage" right split text="Download Image">
              <b-dropdown-item @click="copyImageDownloadLink">Copy Image Link To Clipboard</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card header="Resources">
          <h4>Flashing Methods</h4>
          <h5 class="mt-4">1) USB Blaster</h5>
          <p>The USB blaster is a tool created by Technologic Systems Inc. to flash an image to the
          <b>TS-7553-V2</b> <abbr title="Single-board computer">SBC</abbr> from a USB flash drive.
           This is a slightly modified version of this tool that supports images
           compressed with <b>GZIP</b> and <b>FAT32</b> formatted flash drives.
           You can find more information in the
           <a href="https://docs.embeddedarm.com/TS-7553-V2#Production_Mechanism" target="_blank">official documentation</a></p>
          <h6>Usage</h6>
          <ul>
            <li>Download ZIP of USB blaster from the link bellow</li>
            <li>Extract contents into a <b>FAT32</b> formatted flash drive</li>
            <li>Build and download a gateway image with this tool</li>
            <li>Copy the generated <b>emmcimage.tgz</b> or <b>sdcard.tgz</b> to the flash drive</li>
            <li>Remove USB flash drive from PC and insert in <b>TS-7553-V2</b> gateway</li>
            <li>Disconnect 5V power cable</li>
            <li>Press and hold RST button for 5 seconds</li>
            <li>Reconnect 5V power cable</li>
            <li>Wait for the flashing script to finish. The LED indicator should blink green if flash was successfull or red if it failed</li>
          </ul>
          <a href="https://gib.morgansolar.xyz/blaster.zip" download="blaster.zip">Download USB blaster</a>

          <h5 class="mt-4">2) Manual Flashing</h5>
          <p>You can follow the instructions from the
          <a href="https://github.com/morgansolar/TS-7553-V2_installation#3-preparing-the-sd-card" target="_blank">manual installation procedure</a>.
          The only difference instead of downloading the
          <abbr title="ftp://ftp.embeddedarm.com/ts-arm-sbc/ts-7553-V2-linux/distributions/ts7553-V2-latest.tar.bz2">standard image</abbr>
          copy a download link from this tool</p>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import miniToastr from 'mini-toastr';
import copy from 'copy-text-to-clipboard';
import KeyValueEditor from '../components/KeyValueEditor.vue';

const tzdata = () => import('tzdata');
miniToastr.init();

export default {
  components: {
    KeyValueEditor,
  },
  data() {
    return {
      defaultEnv: {
        GATEWAY_ID: null,
        GATEWAY_NAME: null,
        GATEWAY_SITE_CODE: null,
        GATEWAY_TZ: null,
        GATEWAY_ENV: null,
        GATEWAY_REVSSH_PORT: null,
        GATEWAY_REVSSH_SERVER: 'ssh.morgansolar.xyz',
        GATEWAY_REVSSH_SERVER_LEGACY: '68.232.68.134',
      },
      extraEnv: {},
      timezoneList: [],
      imgTarget: 'emmcimage',
      imgTargetOptions: [
        { text: 'eMMC', value: 'emmcimage' },
        { text: 'SD', value: 'sdimage' }
      ],
      downloadDisabled: false,
      issueRevsshKeys: true,
      issueBrokerCredentials: true,
      issueGoogleCloudCredentials: true,
    };
  },
  async mounted() {
    if (this.timezoneList.length === 0) {
      const {
        default: { zones },
      } = await tzdata();
      this.timezoneList = Object.keys(zones);
    }
  },
  computed: {
    gatewayIdValid() {
      const gatewayId = this.defaultEnv.GATEWAY_ID;
      if (gatewayId == null) return null;
      return /^(((GID|SID)[0-9]{10})|((DGID|SPID)[0-9]{9}))$/.test(gatewayId);
    },
    nameValid() {
      const name = this.defaultEnv.GATEWAY_NAME;
      if (name == null) return null;
      return name.length >= 3;
    },
    siteCodeValid() {
      const siteCode = this.defaultEnv.GATEWAY_SITE_CODE;
      if (siteCode == null) return null;
      return /^(([DS]|SP)-)?[A-Z0-9]{3,5}$/.test(siteCode);
    },
    timezoneValid() {
      const timezone = this.defaultEnv.GATEWAY_TZ;
      if (timezone == null || timezone === '') return null;
      return this.timezoneList.includes(timezone);
    },
    portValid() {
      const port = this.defaultEnv.GATEWAY_REVSSH_PORT;
      if (port == null || port === '') return null;
      return +port > 50000 && +port < 50999;
    },
    envValid() {
      const env = this.defaultEnv.GATEWAY_ENV;
      if (env == null || env === '') return null;
      return /[a-z][a-z0-9]+/.test(env);
    },
    cleanEnv() {
      const env = {};
      Object.entries(this.extraEnv).forEach(([k, v]) => {
        let cleanKey = k
          .replace(/[\s-]+/g, '_')
          .replace(/[^a-zA-Z0-9_]/g, '')
          .toUpperCase();
        if (!cleanKey.startsWith('GATEWAY_')) {
          cleanKey = `GATEWAY_${cleanKey}`;
        }
        env[cleanKey] = v;
      });
      Object.entries(this.defaultEnv).forEach(([k, v]) => {
        if (k === 'GATEWAY_REVSSH_PORT' && !this.issueRevsshKeys && !this.portValid) return;
        if (v == null || v === '') return;
        env[k] = v;
      });

      return env;
    }
  },
  methods: {
    validateForm() {
      return Object.values(this.$refs).reduce((acc, r) => acc && r.checkValidity(), true);
    },
    async getImageToken() {
      const res = await this.$fetch('https://gib.morgansolar.xyz/token', {
        method: 'POST',
        body: {
          env: this.cleanEnv,
          issueRevsshKeys: this.issueRevsshKeys,
          issueBrokerCredentials: this.issueBrokerCredentials,
          issueGoogleCloudCredentials: this.issueGoogleCloudCredentials,
        },
      });
      return JSON.parse(res);
    },
    async downloadImage() {
      const valid = this.validateForm();
      if (!valid) {
        miniToastr.error('Invalid or missing required fields', 'Validation Error');
        return;
      }

      console.log('building image');
      const imgToken = await this.getImageToken();

      if (imgToken.token) {
        const url = `https://gib.morgansolar.xyz/${imgToken.token}/${this.imgTarget}.tgz`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${this.imgTarget}.tgz`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadDisabled = true;
        setTimeout(() => { this.downloadDisabled = false; }, 40000);
      } else {
        miniToastr.error(imgToken.message || 'failed to build image', 'Image Error');
        console.error(imgToken);
      }
    },
    async copyImageDownloadLink() {
      const valid = this.validateForm();
      if (!valid) {
        miniToastr.error('Invalid or missing required fields', 'Validation Error');
        return;
      }

      const imgToken = await this.getImageToken();

      if (imgToken.token) {
        copy(`https://gib.morgansolar.xyz/${imgToken.token}/${this.imgTarget}.tgz`);
      } else {
        miniToastr.error(imgToken.message || 'failed to get image token', 'Image Error');
        console.error(imgToken);
      }
    },
  },
};
</script>

<style>
</style>
